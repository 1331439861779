import ProfileInsiderLayout from "../../../components/Layout/ProfileInsiderLayout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import moment from "moment";
import axios from "axios";
import { DMF_BASE_URL, DMF_GETUSERDETAILS_API_URL } from "../../../constants";
import { DMF_URL } from "../../../constants";
import { useState, useEffect } from "react";
import commonEncode from "../../../commonEncode";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  CheckSession,
  getUserId,
  fetchUserData,
  fetchEncryptData,
  loginRedirect,
} from "../../../common_utilities";
import { DMF_GET_USER_PROFILE_STATE_URL } from "../../../constants";
import { IS_DIRECT } from "../../../constants";
import { apiCall } from "../../../common_utilities";
import styles from "./style.module.css";
import FintooInlineLoader from "../../../components/FintooInlineLoader";
import WhiteOverlay from "../../../components/HTML/WhiteOverlay";
import FintooLoader from "../../../components/FintooLoader";
import ProfilePercentage from "../../../components/ProfilePercentage";

const ProfileInsiderDashboard = (props) => {
  const [userDetails, setUserDetails] = useState({});
  const memberChanged = useSelector((state) => state.memberChanged);
  const [error, setError] = useState(false);
  const [userPan, setUserPan] = useState("");
  const navigate = useNavigate();
  const [profileStatus, setProfileStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [dis, setDis] = useState("");
  const [holdingNature, setHoldingNature] = useState("");

  useEffect(function () {
    onLoadInIt();
    // CheckSession();
    var userid = getUserId();
    if (userid) {
      checkUserData();
    }
    if(getUserId() == null) {
          loginRedirect();
        }
  }, []);

  useEffect(() => {
    onLoadInIt();
  }, []);

  const checkUserData = async () => {
    var userData = await fetchUserData(true);
    if(userData.pan == "") {
      if(userData.is_minor == "Y" && userData.bse_reg =="Y"){
        navigate(process.env.PUBLIC_URL + "/mutual-fund/profile/dashboard");
      }else{
        navigate(process.env.PUBLIC_URL + "/mutual-fund/profile");
      }
     
    }
  };

  const onLoadInIt = async () => {
    try {
      var userid = getUserId();
      setIsLoading(true);
      // if (localStorage.getItem("session_user_id") != null) {
      //   userid = localStorage.getItem("session_user_id");
      // }

      // if (localStorage.getItem("memberid")) {
      //   userid = localStorage.getItem("memberid");
      // }

      var urldata = { user_id: '' + userid, is_direct:'' + IS_DIRECT};
      // var data = commonEncode.encrypt(JSON.stringify(urldata));

      var config = {
        method: "post",
        url: DMF_GETUSERDETAILS_API_URL,
        data:urldata,
      };
      var response = await fetchEncryptData(config);
      var userDetails = response.data;
      if (userDetails.user_holding_nature == "single") {
        setHoldingNature("Single");
      } else if (userDetails.user_holding_nature == "joint") {
        setHoldingNature("Joint");
      } else if (userDetails.user_holding_nature == "anyone_survivor") {
        setHoldingNature("Anyone or Survivor");
      } else {
        setHoldingNature("");
      }
      setUserPan(userDetails.pan);
      var config = {
        method: "post",
        url: DMF_BASE_URL + "api/user/getfatcadetails",
        data: urldata,
      };

      var res2 = await fetchEncryptData(config);
      var config1 = {
        method: "get",
        url: DMF_URL + "restapi/getoccuptionlist/?web=1",
      };
      var res1 = await fetchEncryptData(config1);
      var userDetails2 = res1;
      setUserDetails({
        userDetails2,
        userDetails,
        fatca_politically_exposed: response.fatcadetails,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(true);
    }
  };

  var residential_status = "";
  var politically_exposed = "";
  if (userDetails) {
    residential_status = userDetails.userDetails?.residential_status;
    politically_exposed = userDetails.userDetails?.politically_exposed;
    if (residential_status == "1") {
      residential_status = "Resident";
    } else if (residential_status == "2") {
      residential_status = "NRI";
    } else if (residential_status == "3") {
      residential_status = "NRE/NRO";
    } else {
      residential_status = "";
    }
   if (politically_exposed == "1") {
      politically_exposed = "Yes";
    } else if (politically_exposed == "3") {
      politically_exposed = "Partially";
    } else {
      politically_exposed = "No";
    }
  }


  const displayOccupation = () => {
    if (
      "userDetails" in userDetails &&
      "userDetails2" in userDetails &&
      userDetails.userDetails2.filter(
        (v) => v.occupation_id == userDetails.userDetails.occupation
      ).length > 0
    ) {
      return userDetails.userDetails2.filter(
        (v) => v.occupation_id == userDetails.userDetails.occupation
      )[0]["occupation_name"];
    } else {
      ("-");
    }
  };

  const cleanAddress = () => {
    var a = "";
    if ("userDetails" in userDetails) {
      if ("address" in userDetails.userDetails) {
        a =
          userDetails.userDetails.address +
          " ," +
          userDetails.userDetails.city_name +
          " , " +
          userDetails.userDetails.state_name +
          " , " +
          userDetails.userDetails.country_name;
      } else {
        a =
          ("flat_no" in userDetails.userDetails
            ? userDetails.userDetails.flat_no
            : "") +
          ", " +
          ("building_name" in userDetails.userDetails
            ? userDetails.userDetails.building_name
            : "") +
          ", " +
          ("area" in userDetails.userDetails
            ? userDetails.userDetails.area
            : "") +
          ", " +
          ("userDetails" in userDetails.userDetails
            ? userDetails.userDetails.road_street
            : "") +
          ", " +
          ("city_name" in userDetails.userDetails
            ? userDetails.userDetails.city_name
            : "") +
          ", " +
          ("state_name" in userDetails.userDetails
            ? userDetails.userDetails.state_name
            : "") +
          ", " +
          ("country_name" in userDetails.userDetails
            ? userDetails.userDetails.country_name
            : "");
      }
    }

    a = a
      .split("")
      .filter((v, i) => !(v === " " && v === a[i - 1]))
      .join("");
    // remove space before comma
    a = a.replaceAll(" ,", ",");
    // remove extra comma
    a = a.split("").filter((v, i) => !(v === "," && v === a[i - 1]));
    // add space after comma
    a = a
      .map((v, i) => (v === "," && a[i + 1] !== " " ? v + " " : v))
      .join("")
      .trim();
    return a;
  };

  return (
    <ProfileInsiderLayout prog={dis}>
      <FintooLoader isLoading={isLoading1} />
      <div className="ProfileDashboard">
        <div className="ml-10 md:mt-14 mt-lg-4 p-2 md:p-3 rounded-3xl">
          <div>
            <div className="row">
              <div className="col-lg-8">
                <div className="d-flex">
                  <Link to={process.env.PUBLIC_URL + '/mutual-fund/portfolio/dashboard'} style={{
                    paddingRight: 20
                  }}>
                    <img style={{
                      transform: 'rotate(180deg)'
                    }} width={20} height={20} src={process.env.PUBLIC_URL + '/static/media/icons/chevron.svg'} />
                  </Link>
                  <div className="row" style={{ flexGrow: 1 }}>
                    <div
                      className={`col-12 col-lg-6 ${styles["text-label-info"]}`}
                    >
                      <p className="text-label">Basic Details</p>
                      <p className="secondTextinfo" style={{ color: "#7f7f7f" }}>
                        Information as per KYC
                      </p>
                    </div>
                    <div className={`col-12 col-lg-6 d-flex ${styles["pro-con8"]}`}>
                      <ProfilePercentage />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className={styles.Hrline}></p>
          <div>
            <Row>
              <Col xs={12} lg={8}>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="P-details mobileData details-fi ProfileName">
                      <div className="Text-label">Name</div>
                      <div
                        className="Text-label-Data"
                        style={{ textTransform: "capitalize" }}
                      >
                        {"userDetails" in userDetails &&
                        "name" in userDetails.userDetails &&
                        userDetails.userDetails.name.toLowerCase()
                          ? userDetails.userDetails.name + " " + userDetails.userDetails.middle_name + " " + userDetails.userDetails.last_name
                          : ""}{" "}
                        {<>&nbsp;</>}
                      </div>
                    </div>

                    <div className="P-details">
                      <div className="Text-label">Email</div>
                      <div className="Text-label-Data">
                        {"userDetails" in userDetails &&
                        userDetails.userDetails.email
                          ? userDetails.userDetails.email
                          : ""}
                        {<>&nbsp;</>}
                      </div>
                    </div>

                    <div className="P-details">
                      <div className="Text-label">Residential Status</div>
                      <div className="Text-label-Data">
                        {residential_status}
                        {<>&nbsp;</>}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="P-details details-fi">
                      <div className="Text-label">PAN</div>
                      <div className="Text-label-Data">
                        {"userDetails" in userDetails &&
                        userDetails.userDetails.pan
                          ? userDetails.userDetails.pan
                          : ""}
                        {<>&nbsp;</>}
                      </div>
                    </div>
                    <div className="P-details">
                      <div className="Text-label">Marital Status</div>
                      <div
                        className="Text-label-Data"
                        style={{ textTransform: "capitalize" }}
                      >
                        {"userDetails" in userDetails &&
                        userDetails.userDetails.marital_status
                          ? userDetails.userDetails.marital_status
                          : ""}
                        {<>&nbsp;</>}
                      </div>
                    </div>
                    <div className="P-details">
                      <div className="Text-label">Occupation</div>
                      <div className="Text-label-Data">
                        {displayOccupation() != "" ? displayOccupation() : ""}
                        {<>&nbsp;</>}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="P-details details-fi">
                      <div className="Text-label">Mobile Number</div>
                      <div className="Text-label-Data">
                        {"userDetails" in userDetails &&
                        userDetails.userDetails.mobile
                          ? userDetails.userDetails.mobile
                          : ""}
                      </div>
                    </div>
                    <div className="P-details">
                      <div className="Text-label">Date of Birth</div>
                      <div className="Text-label-Data">
                        {"userDetails" in userDetails &&
                        Boolean(userDetails.userDetails.dob) &&
                        userDetails.userDetails.dob != ""
                          ? moment(userDetails.userDetails.dob,"DD-MM-YYYY").format(
                              "DD-MM-YYYY"
                            )
                          : ""}
                        <>&nbsp;</>

                        <span className="LineHr"></span>
                      </div>
                    </div>
                    <div className="P-details">
                      <div className="Text-label">Account Holding Nature</div>
                      <div className="Text-label-Data">
                        {holdingNature}
                        {<>&nbsp;</>}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div>
            <Row>
              <Col xs={12} lg={8}>
                <Row>
                  <Col xs={12} lg={4}>
                    <div className="P-details">
                      <div className="Text-label">Politically Exposed</div>
                      <div className="Text-label-Data">
                        {politically_exposed}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={8}>
                    <div className="P-details addressDetails" >
                      <div className="Text-label">Residential Address</div>
                      <div className="Address-Data Text-label-Data">
                        {cleanAddress() == "" ? "" : cleanAddress()}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </ProfileInsiderLayout>
  );
};

export default ProfileInsiderDashboard;
