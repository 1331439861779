import { useState, useEffect, useRef } from "react";
import "react-responsive-modal/styles.css";
import Link from "../../../MainComponents/Link";
import Profile_1 from "../../../Assets/05_Fatca_details.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Container, Row, Col } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import FatcaAddress from "../../ProfileCompoenents/Fatca/FatcaAddress";
import Back from "../../../Assets/left-arrow.png";
import FintooButton from "../../../HTML/FintooButton";
import FintooProfileBack from "../../../HTML/FintooProfileBack";
import { FaTrashAlt, FaEdit, FaMinusCircle } from "react-icons/fa";
import { CheckSession, memberId } from "../../../../common_utilities";
import commonEncode from "../../../../commonEncode";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { DMF_ADD_FATCA_API_URL, DMF_ADD_USER_NRI_DETAILS_API_URL, DMF_BASE_URL, DMF_GETCITIES_API_URL, DMF_GETCOUNTRIES_API_URL, DMF_GETSTATES_API_URL, DMF_GETUSERDETAILS_API_URL, IS_DIRECT } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

function FatcaAdd(props) {
  const initialDataRef = useRef(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const showBack = useSelector((state) => state.isBackVisible);
  const [addMore, setAddMore] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editNumber, setEditNumber] = useState(null);
  const editValid = useRef();

  const [userDetails, setUserDetails] = useState("");
  const [userFatcaDetails, setUserFatcaDetails] = useState("");
  const [countryList, setCountries] = useState([]);
  const [stateList, setStates] = useState([]);
  const [cityList, setCities] = useState([]);

  const [taxData, setTaxData] = useState([]);

  var [countryId, setCountryId] = useState("");
  var [stateId, setStateId] = useState("");
  var [osAddress, setOSAddress] = useState("");
  var [zipCode, setZipCode] = useState("");
  var [cityId, setCityId] = useState("");

  const dispatch = useDispatch();

  const [formObject, setFormObject] = useState({});
  const [demo, setDemo] = useState("");

  const user_id = memberId();
  const [tax_res, set_tax_res] = useState();
  const [pol_stat, set_pol_stat] = useState();

  useEffect(()=> {
    set_tax_res(props.maindata.residential_status);
    set_pol_stat(props.maindata.politically_exposed);
  }, []);

  const [, forceUpdate] = useState();

  const defaultValues = async () => {
    if (userDetails.user_nri_address != "") {
      setOSAddress(userDetails.user_nri_address);
    } else {
      setOSAddress("");
    }
    if (userDetails.user_nri_pincode != "") {
      setZipCode(userDetails.user_nri_pincode);
    } else {
      setZipCode("");
    }
    if (userDetails.user_nri_country != "") {
      setCountryId(userDetails.user_nri_country);
    } else {
      setCountryId("");
    }
    if (userDetails.user_nri_city != "") {
      setCityId(userDetails.user_nri_city);
    } else {
      setCityId("");
    }
    if (userDetails.user_nri_state != "") {
      setStateId(userDetails.user_nri_state);
    } else {
      setStateId("");
    }
  };

  useEffect(() => {
    defaultValues();
  }, [userDetails]);

  useEffect(() => {
    getCountries();
    // CheckSession();
    onLoadInIt();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    return () => {
      initialDataRef.current = false;
    };
  }, []);

  const apiFatcaDetails = async () => {
    var data = { user_id: user_id,is_direct :IS_DIRECT };
    try {
      var data = commonEncode.encrypt(JSON.stringify(data));
      var config = {
        method: "post",
        url: DMF_BASE_URL + "api/user/getfatcadetails",
        data: data,
      };
      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      const a = JSON.parse(response)["data"][0];
      // format response
      var maindata = [];
      Object.keys(a).forEach((v, i) => {
        if (v.indexOf("fatca_tax_player_id") > -1 && a[v] !== "") {
          var lastChar = v.charAt(v.length - 1);
          if (isNaN(Number(lastChar)) === true) lastChar = 0;
          if (maindata[lastChar * 1] === undefined) maindata[lastChar * 1] = {};
          maindata[lastChar * 1]["fatca_tax_player_id"] = a[v];
        }
        if (v.indexOf("fatca_resident_country") > -1 && a[v] > 0) {
          var lastChar = v.charAt(v.length - 1);
          if (isNaN(Number(lastChar)) === true) lastChar = 0;
          if (maindata[lastChar * 1] === undefined) maindata[lastChar * 1] = {};
          maindata[lastChar * 1]["fatca_resident_country"] = a[v];
        }
        if (v.indexOf("fatca_id_type") > -1 && a[v] !== "") {
          var lastChar = v.charAt(v.length - 1);
          if (isNaN(Number(lastChar)) === true) lastChar = 0;
          if (maindata[lastChar * 1] === undefined) maindata[lastChar * 1] = {};
          maindata[lastChar * 1]["fatca_id_type"] = a[v];
        }
      });
      if (maindata.length > 0) {
        setUserFatcaDetails(maindata);
      }
    } catch (e) {
      console.log("catch", e);
    }
  };

  useEffect(() => {
    apiFatcaDetails();
  }, [formObject]);

  useEffect(() => {
    if (
      countryList.length &&
      userFatcaDetails.length &&
      initialDataRef.current === false
    ) {
      initialDataRef.current = true;
      setTaxData(
        userFatcaDetails.map((v) => ({
          taxpayer: v.fatca_tax_player_id,
          country: v.fatca_resident_country,
          iType: v.fatca_id_type,
        }))
      );
    }
  }, [userFatcaDetails, countryList]);

  const onLoadInIt = async () => {
    var data = { user_id: '' + user_id, is_direct: '' + IS_DIRECT };
    try {
      var data = commonEncode.encrypt(JSON.stringify(data));
      var config = {
        method: "post",
        url: DMF_GETUSERDETAILS_API_URL,
        data: data,
      };
      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      setUserDetails(JSON.parse(response)["data"]);
    } catch (e) {
      console.log("catch", e);
    }
  };

  const editDetails = (event) => {
    if (editMode == true) {
      var formValid = simpleValidator.current.allValid();
      simpleValidator.current.showMessages();
      forceUpdate(1);
      if (formValid == true) {
        cancelForm();
        setEditMode(false);
        editValid.current = true;
      } else {
        editValid.current = false;
      }
    }
  };

  const addDetails = (event) => {
    console.log('nil4', simpleValidator.current);
    if (addMore === true) {
      var formValid = simpleValidator.current.allValid();
      console.log('nil form', formValid)
      forceUpdate(1);
      if (formValid === true) {
        setTaxData((v) => [
          ...v,
          {
            taxpayer: formObject.taxpayer,
            country: formObject.country,
            iType: formObject.iType,
          },
        ]);
        // FatcaApiCall();
        cancelForm();
        // apiFatcaDetails();
      } else {
        simpleValidator.current.showMessages();
        forceUpdate(1);
      }
    }
  };

  const validateForm = () => {
    var formValid = simpleValidator.current.allValid();
    simpleValidator.current.showMessages();
    forceUpdate(1);
    console.log(2323, formValid, simpleValidator.current);
    if (formValid == true) {
      if (addMore == true)
      {
        addDetails();
        // FatcaApiCall();
        // NriApiCall();
      }
      NriApiCall();
      FatcaApiCall();
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const updateData = (i) => {
    var t = taxData;
    t[i] = { ...formObject };
    setTaxData([...t]);
    setFormObject({});
    cancelForm();
    // editNumber(-1);
  };

  const getCountries = async () => {
    try {
      var config = {
        method: "post",
        url: DMF_GETCOUNTRIES_API_URL,
      };

      var res = await axios(config);
      var response = res.data;
      setCountries(response.data);
    } catch (e) {
      console.log("catch", e);
    }
  };

  const getStates = async () => {
    let statesData = { country_id: countryId };
    try {
      var config = {
        method: "post",
        url: DMF_GETSTATES_API_URL,
        data: statesData,
      };
      var res = await axios(config);
      var responseStates = res.data;
      setStates(responseStates.data);
    } catch (e) {
      console.log("catch", e);
    }
  };

  useEffect(() => {
    getStates();
  }, [countryId]);

  const getCities = async () => {
    let citiesData = { state_id: stateId };
    try {
      var config = {
        method: "post",
        url: DMF_GETCITIES_API_URL,
        data: citiesData,
      };
      var res = await axios(config);
      var responseCities = res.data;
      setCities(responseCities.data);
    } catch (e) {
      console.log("catch", e);
    }
  };

  useEffect(() => {
    getCities();
  }, [stateId]);

  const cancelForm = () => {
    setAddMore(false);
    setEditMode(false);
    setEditNumber(null);
    setFormObject({});
    simpleValidator.current.purgeFields();
    forceUpdate(3);
  };

  const FatcaApiCall = async () => {
    
    var data = {
      user_id: user_id,
      fatca_politically_exposed: pol_stat,
      fatca_tax_resident: tax_res,
      is_direct:IS_DIRECT
    };
    var prefix = 0;
    taxData.forEach((v) => {
      data["fatca_tax_payer_id" + (prefix == 0 ? "" : "_" + prefix)] =
        v.taxpayer;
      data["fatca_id_type" + (prefix == 0 ? "" : "_" + prefix)] = v.iType;
      data["fatca_resident_country" + (prefix == 0 ? "" : "_" + prefix)] =
        v.country;
      prefix++;
    });
    
    try {
      var config = {
        method: "post",
        url: DMF_ADD_FATCA_API_URL,
        data: commonEncode.encrypt(JSON.stringify(data)),
      };

      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      var response_obj = JSON.parse(response);

      var error_code = response_obj.error_code;
      if (error_code == "100") {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Taxpayer added successfully.", type: "success" },
        });
        setEditMode(false);
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Something went wrong.", type: "error" },
        });
        setEditMode(true);
      }
    } catch (e) {
      console.log("catch", e);
    }
  };

  const NriApiCall = async () => {
    try {
      var data = {
        user_nri_state: stateId,
        user_nri_city: cityId,
        user_nri_country: countryId,
        user_nri_pincode: zipCode,
        user_nri_address: osAddress,
        user_id: user_id,
        is_direct: IS_DIRECT
      };
      var config = {
        method: "post",
        url: DMF_ADD_USER_NRI_DETAILS_API_URL,
        data: commonEncode.encrypt(JSON.stringify(data)),
      };

      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      var response_obj = JSON.parse(response);

      if (response_obj.error_code == "100") {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "NRI Details Added Successfully.",
            type: "success",
          },
        });
        setTimeout(() => {
          props.onNext();
        }, 2000);
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Something went wrong.", type: "error" },
        });
      }
    } catch (e) {
      console.log("catch", e);
    }
  };

  console.log(formObject, addMore, 'formObject');

  return (
    <>
      <ToastContainer />
      <Row className="reverse">
        <div className="col-12 col-md-6 ProfileImg">
          <div>
            <img src={Profile_1} alt="" />
          </div>
        </div>
        <div className="col-12 col-md-6 RightPanel">
          <div className="rhl-inner">
            {showBack == true && (
              <FintooProfileBack
                title="FATCA Details "
                onClick={() => props.onPrevious()}
              />
            )}

            <div className="Fatca">
              <div
                className=""
                style={{
                  marginTop: "-1rem",
                }}
              >
                <div className="profile-space-1">
                  <Form.Label className="LabelName" htmlFor="inputText">
                    Overseas Address
                  </Form.Label>
                  <FloatingLabel controlId="floatingTextarea2">
                    <textarea
                      className="form-control shadow-none"
                      required
                      placeholder="Enter your address here"
                      style={{
                        height: "140px",
                        borderRadius: "10px",
                        textAlign: "left",
                      }}
                      value={osAddress}
                      onChange={(event) => {
                        setOSAddress(event.target.value);
                      }}
                      maxLength="250"
                    />
                    {simpleValidator.current.message(
                      "overseasAddress",
                      osAddress,
                      "required|min:6|max:250",
                      {
                        messages: {
                          required: "Please enter your Overseas Address.",
                          max: "The Overseas Address should not be greater than 250 characters.",
                          min: "The Overseas Address should at least be of 6 characters.",
                        },
                      }
                    )}
                  </FloatingLabel>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="profile-space-1">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        ZIP code
                      </Form.Label>
                      <input
                        aria-label="Default select example"
                        className="shadow-none form-control"
                        style={{
                          borderRadius: "10px",
                          height: "2.5rem",
                          outline: "none",
                        }}
                        value={zipCode}
                        pattern="^[A-Za-z0-9-]{1,10}$"
                        onChange={(event) => {
                          setZipCode(event.target.value);
                        }}
                        onKeyPress={(event) => {
                          if (!/[A-Za-z0-9-]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength="9"
                      />
                      {simpleValidator.current.message(
                        "zipCode",
                        zipCode,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="profile-space-1">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        Country
                      </Form.Label>
                      <select
                        controlId="validationCustom02"
                        required
                        className="shadow-none form-select"
                        aria-label="Default select example"
                        style={{
                          borderRadius: "10px",
                          height: "2.5rem",
                          outline: "none",
                        }}
                        onChange={(event) => setCountryId(event.target.value)}
                        value={countryId}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        {countryList.map((w) => (
                          <option
                            key={"country-" + w.country_id}
                            value={w.country_id}
                          >
                            {w.country_name}
                          </option>
                        ))}
                      </select>
                      {simpleValidator.current.message(
                        "country",
                        countryId,
                        "required"
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="profile-space-1">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        State
                      </Form.Label>
                      <select
                        controlId="validationCustom02"
                        required
                        className="shadow-none form-select"
                        aria-label="Default select example"
                        style={{
                          borderRadius: "10px",
                          height: "2.5rem",
                          outline: "none",
                        }}
                        onChange={(event) => setStateId(event.target.value)}
                        value={stateId}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        {stateList.map((b) => (
                          <option value={b.state_id}>{b.state_name}</option>
                        ))}
                      </select>
                      {simpleValidator.current.message(
                        "state",
                        stateId,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="profile-space-1">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        City
                      </Form.Label>
                      <select
                        controlId="validationCustom02"
                        required
                        className="shadow-none form-select"
                        aria-label="Default select example"
                        style={{
                          borderRadius: "10px",
                          height: "2.5rem",
                          outline: "none",
                        }}
                        onChange={(event) => setCityId(event.target.value)}
                        value={cityId}
                      >
                        <option value="" selected disabled>
                          Select
                        </option>
                        {cityList.map((x) => (
                          <option value={x.city_id}>{x.city_name}</option>
                        ))}
                      </select>
                      {simpleValidator.current.message(
                        "city",
                        cityId,
                        "required"
                      )}
                    </div>
                  </div>
                </div>

                {[...taxData].map((v, i) => (
                  <div key={`ff-${i}-${v.taxpayer}`}>
                    {editNumber == i ? (
                      <></>
                    ) : (
                      <div className="d-flex mylist-items my-3">
                        <div className="txi-item">
                          <div className="my-badge">{i + 1}</div>
                        </div>
                        <table className="flex-grow-1 ftc-tbl">
                          <tr>
                            <td>
                              <p className="tbl-head">Taxpayer ID</p>
                              <p className="tbl-txt">{v.taxpayer}</p>
                            </td>
                            <td>
                              <p className="tbl-options">
                                <span>
                                  <FaTrashAlt
                                    onClick={() => {
                                      setTaxData((x) =>
                                        [...x].filter((b, k) => k != i)
                                      );
                                    }}
                                  />
                                </span>
                                <span>
                                  <FaEdit
                                    onClick={() => {
                                      setFormObject({ ...v });
                                      setEditNumber(i);
                                      setAddMore(false);
                                      setEditMode(true);
                                    }}
                                  />
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="tbl-head">Identification Type</p>
                              <p className="tbl-txt">{v.iType}</p>
                            </td>
                            <td>
                              <p className="tbl-head">Country</p>
                              <p className="tbl-txt">
                                {
                                  countryList.filter(
                                    (x) => x.country_id == v.country
                                  )[0]["country_name"]
                                }
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    )}
                    {editNumber == i && (
                      <div className="mylist-items my-3 position-relative">
                        <div className="btn-cross">
                          <FaMinusCircle
                            className=" fintoo-blue"
                            onClick={() => cancelForm()}
                          />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="profile-space-1">
                              <Form.Label
                                className="LabelName"
                                htmlFor="inputText"
                              >
                                Taxpayer ID
                              </Form.Label>
                              <input
                                value={formObject.taxpayer}
                                onChange={(event) => {
                                  setFormObject((x) => ({
                                    ...x,
                                    taxpayer: event.target.value,
                                  }));
                                }}
                                pattern="^(9\d{2})([ \-]?)([7]\d|8[0-8])([ \-]?)(\d{4})$"
                                maxLength={11}
                                aria-label="Default select example"
                                className="shadow-none form-control"
                              />
                              {simpleValidator.current.message(
                                "taxpayerId",
                                formObject.taxpayer,
                                "required"
                              )}
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div className="profile-space-1">
                              <Form.Label
                                className="LabelName"
                                htmlFor="inputText"
                              >
                                Country
                              </Form.Label>
                              <select
                                controlId="validationCustom02"
                                required
                                className="shadow-none form-select"
                                aria-label="Default select example"
                                style={{
                                  borderRadius: "10px",
                                  height: "2.5rem",
                                  outline: "none",
                                }}
                                onChange={(event) => {
                                  setFormObject((x) => ({
                                    ...x,
                                    country: event.target.value,
                                  }));
                                }}
                              >
                                <option value="0" selected disabled>
                                  Select
                                </option>
                                {countryList.map((c) => (
                                  <option
                                    selected={
                                      c.country_id == formObject.country
                                    }
                                    value={c.country_id}
                                  >
                                    {c.country_name}
                                  </option>
                                ))}
                              </select>
                              {simpleValidator.current.message(
                                "country",
                                formObject.country,
                                "required"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="profile-space-1 pb-0">
                              <Form.Label
                                className="LabelName"
                                htmlFor="inputText"
                              >
                                Identification Type
                              </Form.Label>
                              <input
                                controlId="validationCustom02"
                                required
                                className="shadow-none form-control"
                                aria-label="Default select example"
                                style={{
                                  borderRadius: "10px",
                                  height: "2.5rem",
                                  outline: "none",
                                }}
                                maxLength="20"
                                onChange={(event) => {
                                  setFormObject((x) => ({
                                    ...x,
                                    iType: event.target.value,
                                  }));
                                }}
                                value={formObject.iType}
                              />

                              {simpleValidator.current.message(
                                "IdentificationType",
                                formObject.iType,
                                "required"
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-6 ">
                            <div className="profile-space-1 pb-0">
                              <Form.Label className="LabelName">
                                &nbsp;
                              </Form.Label>
                              <div>
                                <FintooButton
                                  type="button"
                                  className={`d-block me-0 ms-auto`}
                                  onClick={() => {
                                    editDetails();
                                    editValid.current ? updateData(i) : forceUpdate(2);
                                  }}
                                  title={"Save"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                {addMore && (
                  <div className="mylist-items my-3 position-relative">
                    <div className="btn-cross">
                      <FaMinusCircle
                        className=" fintoo-blue"
                        onClick={() => cancelForm()}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="profile-space-1">
                          <Form.Label className="LabelName" htmlFor="inputText">
                            Taxpayer ID
                          </Form.Label>
                          <input
                            aria-label="Default select example"
                            className="shadow-none form-control"
                            onChange={(e) => {
                              setDemo(e.target.value);
                              setFormObject((v) => ({
                                ...v,
                                taxpayer: e.target.value,
                              }));
                            }}
                            maxLength={11}
                          />
                          
                          {simpleValidator.current.message(
                            "taxpayerId",
                            formObject.taxpayer,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="profile-space-1">
                          <Form.Label className="LabelName" htmlFor="inputText">
                            Country
                          </Form.Label>
                          <select
                            controlId="validationCustom02"
                            required
                            className="shadow-none form-select"
                            aria-label="Default select example"
                            style={{
                              borderRadius: "10px",
                              height: "2.5rem",
                              outline: "none",
                            }}
                            onChange={(e) => {
                              setFormObject((v) => ({
                                ...v,
                                country: e.target.value,
                              }));
                            }}
                          >
                            <option value="0" selected disabled>
                              Select
                            </option>
                            {countryList.map((a) => (
                              <option value={a.country_id}>
                                {a.country_name}
                              </option>
                            ))}
                          </select>
                          {simpleValidator.current.message(
                            "country",
                            formObject.country,
                            "required"
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="profile-space-1 pb-0">
                          <Form.Label className="LabelName" htmlFor="inputText">
                            Identification Type
                          </Form.Label>
                          <input
                            controlId="validationCustom02"
                            required
                            className="shadow-none form-control"
                            aria-label="Default select example"
                            style={{
                              borderRadius: "10px",
                              height: "2.5rem",
                              outline: "none",
                            }}
                            onChange={(e) => {
                              setFormObject((v) => ({
                                ...v,
                                iType: e.target.value,
                              }));
                            }}
                            maxLength="20"
                          />
                          {simpleValidator.current.message(
                            "IdentificationType",
                            formObject.iType,
                            "required"
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 ">
                        <div className="profile-space-1 pb-0">
                          <Form.Label className="LabelName">&nbsp;</Form.Label>
                          <div>
                            <FintooButton
                              type="button"
                              className={`d-block me-0 ms-auto`}
                              onClick={() => {
                                addDetails();
                              }}
                              title={"Save"} //button to add taxpayer ID
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {taxData.length < 3 && (
                  <div
                    className="profile-addmore noselect"
                    onClick={() => {
                      simpleValidator.current.hideMessages();
                      forceUpdate(2);
                      setAddMore(true);
                      setEditMode(false);
                      setEditNumber(null);
                      setFormObject({});
                    }}
                  >
                    + Add New Taxpayer ID
                  </div>
                )}
              </div>

              <div className="profile-space d-flex fintoo-top-border mt-4">
                <p className="mt-1">
                  <b>Note</b>: You can add up to 3 Taxpayer IDs
                </p>
                <FintooButton
                  className={`d-block me-0 ms-auto`}
                  onClick={() => validateForm()}
                  title={"Next"}
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}

export default FatcaAdd;
